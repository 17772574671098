import {LitElement, html} from 'lit';
import SimpleLightbox from "simplelightbox";
import {createRef, ref} from 'lit/directives/ref.js';
import {getRelativeOffset} from "../utils";

export class CoverImage extends LitElement {
  static get properties() {
    return {
      src: {type: String},
      thumbnailSrc: {type: String},
      alt: {type: String},
      crop: {type: Object},
    }
  }

  lightboxRef = createRef();
  coverRef = createRef();
  lightbox = null;

  firstUpdated() {
    if (this.src) {
      this.lightbox = new SimpleLightbox(
        [this.lightboxRef.value],
        {
          fileExt: false,
          scrollZoomFactor: 0.05,
          overlayOpacity: 0.9,
          widthRatio: 1,
        }
      )
      if (this.crop) {
        const img = new Image()
        img.src = this.thumbnailSrc
        img.onload = () => {
          this.applyCrop(img);
        }
      }
    }
  }

  createRenderRoot() {
    return this;
  }

  render() {
    if (this.src) {
      return html`
        <link rel="preload" as="image" href="${this.src}">
        <a href="${this.src}" ${ref(this.lightboxRef)} alt="${this.alt}">
          <div class="cover" ${ref(this.coverRef)} style="background-image: url('${this.thumbnailSrc}')"></div>
        </a>
      `
    }
    return html`
      <div class="event event--cover">
        <div class="event__placeholder" style="background-image: url('${window.placeholderImage}')"></div>
      </div>
    `;
  }

  applyCrop(img) {
    const [scaledX, scaledY] = getRelativeOffset(
      this.coverRef.value.clientWidth,
      this.crop.cropWidth,
      this.crop.offsetX,
      this.crop.offsetY
    )

    const [scaledWidth, scaledHeight] = this.getImageScale(
      img,
      this.crop.cropScale,
      this.crop.cropWidth,
      this.crop.cropHeight,
      this.coverRef.value.clientWidth,
      this.coverRef.value.clientHeight
    )

    this.coverRef.value.style.backgroundPosition = `${scaledX}px ${scaledY}px`;
    this.coverRef.value.style.backgroundSize = `${scaledWidth}px ${scaledHeight}px`;
  }

  getImageScale(img, scale, cropWidth, cropHeight, clientWidth, clientHeight) {
    let baseScale;
    if (img.naturalWidth / img.naturalHeight > cropWidth / cropHeight) {
      baseScale = clientWidth / img.naturalWidth;
    } else {
      baseScale = clientHeight / img.naturalHeight;
    }

    const relativeScale = baseScale * this.crop.cropScale;

    return [img.naturalWidth * relativeScale, img.naturalHeight * relativeScale]
  }
}

customElements.define('cover-image', CoverImage);
