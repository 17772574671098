function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

/**
 * Debounce both sync and async functions.
 * @param {Function} func - Function to be executed.
 * @param {number} wait - Time to wait before executing function in milliseconds.
 * @returns {Function} - Debounced function.
 */
export function debounce(func, wait = 300) {
  let timeout
  if (func[Symbol.toStringTag] === 'AsyncFunction') {
    return async function (...args) {
      return new Promise((resolve, reject) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          timeout = null
          Promise.resolve(func.apply(this, [...args])).then(resolve).catch(reject)
        }, wait)
      })
    }
  } else {
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        func.apply(this, [...args])
      }, wait)
    }
  }
}

/**
 * Get the relative offset of the crop.
 *
 * Calculates the relative offset of the crop based on the client width and the width during cropping.
 *
 * @param {Number} clientWidth - Current width of element to display in.
 * @param {Number} cropWidth - Width of element during cropping.
 * @param {Number} offsetX - Offset in x direction.
 * @param {Number} offsetY - Offset in y direction.
 * @returns {number[Number, Number]} - Relative offset in x and y direction.
 */
export function getRelativeOffset(clientWidth, cropWidth, offsetX, offsetY){
  const pixelScale = clientWidth / cropWidth;
  return [offsetX * pixelScale, offsetY * pixelScale]
}

export {getCookie}
